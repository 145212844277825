<section class="about-section">  
    <div class="page-heading pos-rel">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">About Us</h1>
            <div class="breadcrumbs">
                <ul>
                    <li class="item-home">
                        <a class="bread-link bread-home text-white" href="javascript:(0);" routerLink="/home" title="Home">Home</a>
                    </li>
                    <li class="separator separator-home"> 
                        <i class="fa fa-angle-double-right text-white" aria-hidden="true"></i> 
                    </li>
                    <li class="item-current">
                        <span class="bread-current bread-1371">About Us</span>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
    <div class="about-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-12">
                    <div class="btn-group float-right mb-20" role="group" aria-label="Basic example">
                        <button type="button" id="button1" value="larger" class="btn btn-info mr-10" (click)="increasefontsize()">Increase Font</button>
                        <button type="button" id="button2" vale="smaller" class="btn btn-secondary" (click)="decreasefontsize()">Decrease Font</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-6 col-12 md-mb-30">   
                    <div class="card border-0">
                        <div class="card-body">
                            <h3><b>About Caring Approach</b></h3>
                            <hr>
                            <p class="mb-20">Caring Approach was created with the goal of providing quality care for people in the community. The Caring Approach team are professional and experienced care workers, committed to assisting and maintaining a happy and healthy quality of life for our clients. We provide care for the elderly, people with disabilities, people recovering from illness, carers, people with dementia, and mothers.</p>
                            <h5 class="mb-20"><b>We believe in focusing on the individual needs of our clients and their families with a flexible and tailored service devised together</b></h5>
                            <p class="mb-20 pb-50">Everybody has the right to dignity and respect and it is each individuals right to choose the way they are supported and cared for. The support we offer is designed to help each client engage in the daily activities they choose to participate in, enabling people to live independently.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-12">
                    <div class="row">
                        <div class="col-md-6 col-12 img-wrap d-none d-lg-block d-md-none d-sm-none">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class="card border-0 mb-5">
                                        <div class="card-body p-0">
                                            <img src="assets/images/about/about-1.png" class="img-fluid w-100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-12">
                                   <div class="card border-0">
                                        <div class="card-body p-0">
                                            <img src="assets/images/about/about-2.png" class="img-fluid w-100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-12">
                            <div class="row">
                                <div class="col-md-12 col-12">
                                    <div class="card border-0">
                                        <div class="card-body p-0">
                                            <div class="box p-20">
                                                <p class="text-white">We understand the importance of self- determination and work closely with our clients to assist them with the activities that are important to them. So whether you need in-home personal care, assistance with cooking, cleaning and household tasks, or support engaging in enjoyable activities or education we are there to help. Our staff members are empathetic and flexible and our services aim to put you in the driver's seat.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<section class="services-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Our Team</h2>
                    <p class="text">We are proud to play a part in helping you live your best life. Our experienced team is committed to help you live your life to the fullest.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class='content'>
                        <div class='slider single-item'>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content">
                                        <div class="info-wrap">
                                            <div class="info">
                                                <img src="assets/images/services/our-services.png" class="img-fluid profile-pic">
                                                <div class="team-header">
                                                    <h3 class="name">Ora Klein</h3>
                                                    <div class="position">Psychologist</div>
                                                    <p class="content">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>-->
    <div class="other-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="d-flex align-items-center mt-20">
                        <img src="assets/images/about/tick.svg" class="img-fluid w-100 mr-15" style="width:20%!important;">
                        <span class="fz-16">We understand <br><span class="text-blue fw-600 fz-20"> that things change</span></span>
                    </div>
                </div>
                <div class="col-md-9">
                    <p>Should your circumstances, needs, or simply your preferences change at any time our services offer the flexibility to fit in with your changing life so that you won't be stuck in the same old routine day in day out. We believe in everyone's right to feel safe</p>
                    <p>It is important to us that all our clients and staff are safe and secure at all times. Therefore all our staff members are highly trained in identifying and correcting or reporting any potential hazards, and in manual handling and safety procedures so that our clients are in the safest of hands.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="services-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="box-wrap">
                        <div class="icon-wrap mb-30 pos-rel">
                            <img src="assets/images/services/support.svg" class="img-fluid normal">
                        </div>
                        <div class="box-content">
                            <h4 class="title mb-10 mt-0"><b>We value teamwork and cooperation</b></h4>
                            <p class="content">The building of trusting and professional relationships is important to us. We believe in caring for all staff members and we encourage our team to engage in conduct that offers each other understanding and consideration at all times.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <a href="javascript:void(0);" routerLink="/">
                        <div class="box-wrap">
                            <div class="icon-wrap mb-30 pos-rel">
                                <img src="assets/images/services/tools.svg" class="img-fluid normal">
                            </div>
                            <div class="box-content">
                                <h4 class="title mb-10 mt-0"><b>Commitment to continued improvement</b></h4>
                                <p class="content">At Caring Approach we are always learning. We believe it is our responsibility to stay updated with new techniques and methods of caring that will benefit our clients.</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-12">
                    <a href="javascript:void(0);" routerLink="/">
                        <div class="box-wrap">
                            <div class="icon-wrap mb-30 pos-rel">
                                <img src="assets/images/services/wheelchair.svg" class="img-fluid normal">
                            </div>
                            <div class="box-content">
                                <h4 class="title mb-10 mt-0"><b>At Caring Approach Integrity is at our core</b></h4>
                                <p class="content">It is our belief that our word is the most valuable thing we can offer. Therefore you can count on us to deliver what we promise. We believe in treating everyone with respect and that everyone has a right to the freedom of choice and to their own beliefs.</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="gov-funded pos-rel inner-wrap">
        <div class="container pos-rel d-flex flex-column align-items-center">
            <h1 class="page-heading-title text-white">Government funded?  We’ve got you covered.</h1>
            <p class="text-white">Caring Approach is registered with NDIS & Myagedcare <br> to help you avail their help and benefits</p>
        </div>
    </div>
    <div class="social-wrap inner-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="theme-box p-20">
                                <h4 class="text-white">After Hours Support</h4>
                                <p class="text-white">Our contracted clients are assured that after hours support is available for both clients and staff if urgent matters arise.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="blue-box p-20">
                                <h4 class="text-white">Easy transfer of services</h4>
                                <p class="text-white">Invoices are provided fortnightly by post or email. We can also work with your government provider to arrange a simple and easy transfer of services.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="box p-20">
                                <h4>Easy Referral</h4>
                                <span>
                                    <i class="fas fa-envelope mr-5"></i>
                                    <a  href="mailto:admin@caringapproach.com.au" class="text-theme" target="_blank"> <span>admin@caringapproach.com.au</span></a>
                                </span><br>
                                <span>
                                   <i class="fas fa-phone mr-5"></i>
                                   <a href="tel:+1300655026"><span>1300 655 026</span></a>
                                </span><br>
                                <span>
                                   
                                    <i class="fas fa-phone mr-5"></i>
                                    <a href="tel:+0431744321"><span>0431 744 321</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
