<section class="hero-bg news">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12 p-0 ">
                <app-hero-slider></app-hero-slider>
            </div>
        </div>
    </div>
</section>
<div class="content-wrapper">
    <section class="about-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title wow fadeInUp" data-wow-delay="0.5s" animation-duration="2s">About Caring Approach</h2>
                    <p class="text">Caring Approach was created with the goal of providing quality care for people in the community. The Caring Approach team are professional and experienced care workers, committed to assisting and maintaining a happy and healthy quality of life for our clients. We provide care for the elderly, people with disabilities, people recovering from illness, carers, people with dementia, and mothers.</p>
                </div>
            </div>
            <div class="row mt-70">
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title mt-100">Strict Recruitment Process</h5>
                    <p class="content">Caring Approach staff meet strict criteria, including reference and key qualification checks.</p>
                    <div class="img-wrap text-right">
                        <img src="assets/images/about/system.svg" class="img-fluid"> 
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title">High Safety Priority</h5>
                    <p class="content">Support workers provide police checks and when relevant Working With Children Checks for added safety and security.</p>
                    <div class="img-wrap text-center">
                        <img src="assets/images/about/check.png" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 text-center">
                    <h5 class="sub-title mt-100">Insurance Coverage</h5>
                    <p class="content">All independent support workers are covered by comprehensive insurance arranged by Caring Approach to guarantee peace of mind for you and your family.</p>
                    <div class="img-wrap text-left">
                        <img src="assets/images/about/shield.svg" class="img-fluid">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <div class="img-wrap text-center d-none d-sm-none d-md-none d-lg-block d-xl-block pos-abs w-100">
                        <img src="assets/images/about/about-arc.png" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="row mt-sm-50 mt-200">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="tag-title">Caring Approach home based aged and disability care services in Sydney and ACT</h2>
                    <a href="javascript:void(0);" routerLink="/contact-us" tabindex="0" class="btn btn-primary theme-btn">CONTACT NOW</a>
                </div>
            </div>
        </div>
    </section>
    <section class="services-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Our Services</h2>
                    <p class="text">Get well sooner with Caring Approach health care solutions</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class='content'>
                        <div class='slider single-item'>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/services/our-services.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/disability-care">
                                        <span>Disability Care</span>
                                    </a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/nursing.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/nursing-care"><span>Nursing Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/dementia.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/dementia-care"><span>Dementia Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/recovery.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/recovery-care"><span>Recovery Care</span></a>
                                    <br><br>
                                </div>
                            </div>
                            <div class='quote-container'>
                                <div class='quote'>
                                    <div class="client-content mb-35">
                                        <img src="assets/images/transport.png" class="img-fluid">
                                    </div>
                                    <a href="javascript:void(0);" routerLink="/transport"><span>Transport</span></a>
                                    <br><br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="our-feature-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Your life. Your choice.</h2>
                    <p class="sub-title">At Caring Approach we understand that every client is an individual, with unique needs and lifestyles. Your happiness, health, and well-being are our highest priority, and so we support you and your family with care specifically tailored to you. The Caring Approach team work one on one with you to create a care plan that will meet your needs, and that will fit in with your lifestyle, so that you have the freedom to live the best life you can live. So whether you need help around the house, support for you and your new baby, or high level disability care we can provide the services you need for you to get on with your life.</p>
                </div>
            </div>
            <div class="row mt-70">
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="feature-item text-center">
                        <h3>After Hours Support</h3>
                        <p>Our contracted clients are assured that after hours support is available for both clients and staff if urgent matters arise.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 mt-md-20">
                    <div class="feature-item text-center">
                        <h3>Easy transfer of services</h3>
                        <p>Invoices are provided fortnightly by post or email. We can also work with your government provider to arrange a simple and easy transfer of services.</p>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12 mt-md-20">
                    <div class="feature-item text-center">
                        <h3>Easy Referral</h3>
                        <p href="mailto:admin@caringapproach.com.au">Email: admin@caringapproach.com.au</p>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12 mt-70">
                    <div class="feature-item text-center">
                        <h3> Telephone: <a class="text-white" href="tel:+1300655026">1300 655 026</a>, <a class="text-white" href="tel:+0431744321">0431 744 321</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="testimonial-section">
        <div class="container">
            <div class="row">
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-12 text-center">
                    <h2 class="title">Client Testimonials</h2>
                    <p class="text">What our clients have to say about Caring Approach</p>
                </div>
            </div>
            <div class="row mt-20">
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster1.png">
                                <source src="assets/video/greg-moran-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Greg Moran
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    My name is Greg Moran, I'm a C3/4 quadriplegic as a result of a rugby accident in 1983. In my 30 odd years as a quadriplegic I can categorically say that Caring Approach Agency... <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster2.png">
                                <source src="assets/video/joanna-willis-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Farah
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I decided to use Caring Approach for my personal and domestic care needs because Homecare did not provide the quality of staff or the flexibility of care that I needed...
                                    <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
                <!-- Testimonial -->
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="box">
                        <!-- Testimonial Image -->
                        <div class="video-wrap mr-5">
                            <video controls="controls" style="width: 100%; height: 100%;" poster="assets/images/testi-poster3.png">
                                <source src="assets/video/mark-testimonial.mp4" type="video/mp4"> Your browser does not support the video tag. 
                            </video>
                        </div>
                        <!-- / Testimonial Image -->
                        <div class="test-component">
                            <!-- Title -->
                            <article class="test-title">
                                <h4>
                                    Mark
                                </h4>
                            </article>
                            <!-- / Title -->
                            <article class="test-content">
                                <p>
                                    I have been receiving home care for approximately nine months. Initially, that care was provided by another agency. It was only after I began using Caring Approach...
                                    <a href="javascript:void(0);" routerLink="/testimonials"><small>Read more >></small></a>
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- / Testimonial -->
            </div>
        </div>
    </section>
    <section class="blog-section">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12 text-center">
                    <h2 class="title">Top Pick Articles</h2>
                    <p class="text">We explore, we research, and we share valuable information and tech updates -abiding by the industry leading software practices -via our blogs penned by our industry experts and sometimes you.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog1.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.ndis.gov.au/participants/independent-assessments/independent-assessment-q-and" target="_blank"> Independent assessment Q and A </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>16 Dec, 2020</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.ndis.gov.au/participants/independent-assessments/independent-assessment-q-and" target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.myagedcare.gov.au/news-and-updates/disability-gateway-now-live" target="_blank" > Disability Gateway now live </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>10 Feb, 2021</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.myagedcare.gov.au/news-and-updates/disability-gateway-now-live" target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-12">
                    <div class="blog-post">
                        <div class="media-materials">
                            <img alt="" src="assets/images/blog/blog2.png" class="img-fluid">  
                        </div>
                        <div class="post-content">
                            <div class="post-title-container">
                                <h3><a href="https://www.health.gov.au/ministers/the-hon-greg-hunt-mp/media/additional-covid-19-mental-health-support" target="_blank"> Additional covid-19 mental health support </a></h3>
                            </div>
                            <div class="post-content-blog">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <h5>02 Aug, 2020</h5>
                                    </div>
                                    <div class="ml-auto align-items-center">
                                        <div class="dl">
                                            <a href="https://www.health.gov.au/ministers/the-hon-greg-hunt-mp/media/additional-covid-19-mental-health-support"  target="_blank" class="card-link read">Read Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-xl-12 col-lg-12 col-12 text-center mt-30">
                    <a href="javascript:void(0);" class="btn btn-primary theme-btn" tabindex="0">VIEW ALL</a>
                </div>-->
            </div>
        </div>
    </section>
</div>

