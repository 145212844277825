import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.single-item').slick({
      infinite: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 1500,
      cssEase: 'ease-in-out',
      slidesToShow: 4,
      nav: false,
      responsive: [
        {
          breakpoint: 640,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nav: false,
          }
        }
      ]      
    });

    $('.quote-container').mousedown(function(){
      $('.single-item').addClass('dragging');
    });
    $('.quote-container').mouseup(function(){
      $('.single-item').removeClass('dragging');
    }); 
  }
  decreasefontsize(){
    $('.inner-wrap h1').css('font-size', '-=1');
    $('.inner-wrap h2').css('font-size', '-=1');
    $('.inner-wrap h3').css('font-size', '-=1');
    $('.inner-wrap h4').css('font-size', '-=1');
    $('.inner-wrap h5').css('font-size', '-=1');
    $('.inner-wrap h6').css('font-size', '-=1');
    $('.inner-wrap p').css('font-size', '-=1');
    $('.inner-wrap a').css('font-size', '-=1');
    $('.inner-wrap li').css('font-size', '-=1');
    $('.inner-wrap span').css('font-size', '-=1');
  }
  increasefontsize(){
    $('.inner-wrap p').css('font-size', '+=2');
    $('.inner-wrap h1').css('font-size', '+=2');
    $('.inner-wrap h2').css('font-size', '+=2');
    $('.inner-wrap h3').css('font-size', '+=2');
    $('.inner-wrap h4').css('font-size', '+=2');
    $('.inner-wrap h5').css('font-size', '+=2');
    $('.inner-wrap h6').css('font-size', '+=2');
    $('.inner-wrap a').css('font-size', '+=2');
    $('.inner-wrap li').css('font-size', '+=2');
    $('.inner-wrap span').css('font-size', '+=2');
  }
}
