import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-hero-slider',
  templateUrl:'./hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent implements OnInit {

  constructor() { }

  heroSlides = [
    {img: 'assets/images/slider/1.jpg', subtitle: 'Quality Disability & Aged Care', title: 'Live Life to the Fullest', detail: 'Registered NDIS and Approved Aged Care providers'},
    {img: 'assets/images/slider/2.jpg', subtitle: 'Respite', title: 'Live Life to the Fullest', detail: 'Registered NDIS and Approved Aged Care providers'},
    {img: 'assets/images/slider/3.jpg', subtitle: 'Plan Management & Support Coordination', title: 'Live Life to the Fullest', detail: 'Registered NDIS and Approved Aged Care providers'},
    {img: 'assets/images/slider/4.jpg', subtitle: 'Allied Health', title: 'Live Life to the Fullest', detail: 'Registered NDIS and Approved Aged Care providers'}
 ];
  heroSliderConfig = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      vertical: false,
      centerPadding: '10px',
      slidesToShow: 1,
      slidesToScroll: 1,
      verticalSwiping: false,
      prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 12px;background-color: transparent;position: absolute;bottom: 22%;z-index: 1;left: 1%;"><i class="fas fa-chevron-left"></i></button>',
      nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 12px;background-color: transparent;position: absolute;bottom: 22%;z-index: 1;right:1%;"><i class="fas fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 812,
          settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow prev-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 5px;background-color: transparent;position: absolute;bottom: 40%;z-index: 1;left: 0%;"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button class="slide-arrow next-arrow btn-primary" style="border: 1px solid #fff;padding: 5px 5px;background-color: transparent;position: absolute;bottom: 40%;z-index: 1;right:0%;"><i class="fas fa-chevron-right"></i></button>',
          }
        }
      ]
  };
  ngOnInit(): void {
  }

}
